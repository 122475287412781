import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import BoltIcon from "@mui/icons-material/Bolt";
import DownloadIcon from "@mui/icons-material/Download";
import ElectricCarIcon from "@mui/icons-material/ElectricCar";
import EvStationIcon from "@mui/icons-material/EvStation";
import FactoryIcon from "@mui/icons-material/Factory";
import {
  Card,
  CardContent,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import axios from "axios";
import Dexie from "dexie";
import html2canvas from "html2canvas";
import Cookie from "js-cookie";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import * as XLSX from "xlsx";

import { LoadingButton } from "@mui/lab";
import { localDb } from "../../../contexts/localDb";
import { SnackBarContext } from "../../../contexts/snackBarContext";
import TYPE_STRINGS from "../../../static/constants/TYPE_STRINGS";
import {
  designCaseURL,
  toggleLockSimulationURL,
} from "../../../static/constants/backendRoutes.js";
import {
  unitAreaMap,
  unitMassMap,
} from "../../../static/constants/systems_of_measurement";
import CO2 from "../../../static/images/co2.png";
import Trees from "../../../static/images/trees.png";
import UseAuth from "../../auth/useAuth";
import DesignCaseDialog from "../../dialogs/designCaseDialog.js";
import Empty from "../../secondary/empty";
import { CustomStepper } from "../../secondary/steppers";
import {
  currencySymbol,
  getUnits,
  unitAcres,
  unitMoney,
  unitPoundToTon,
  unitTon,
} from "../../secondary/unitConversions";
import {
  errorHandler,
  getLocalData,
  getMasterData,
  numberUS,
  storeLocalData,
  stringCapitalize,
} from "../../utils";
import SimulationSubtitle from "../dialogs/simulationSubtitle";
import { LoadProfileGraph } from "../graphs/loadProfileGraph";
import { TCOGraph } from "../graphs/tcoGraph";
import VehicleActivityTimeTable from "../graphs/vehicleActivityTimeTable.js";
import BatteryIcon from "../simulationSummaryViews/batteryIcon";
import Chargers from "../simulationSummaryViews/chargers";
import OperationView from "../simulationSummaryViews/operationView";
import VehiclesBattery from "../simulationSummaryViews/vehiclesBattery";
import VehiclesEnergy from "../simulationSummaryViews/vehiclesEnergy";
import TCOTable from "../tables/tcoTable";
import TCOTableEmissions from "../tables/tcoTableEmissions";

export default function SimulationSummary() {
  const [simData, setSimData] = useState({
    simulation: undefined,
    project: undefined,
    evAssessment: undefined,
    fleetSizing: undefined,
    tco: undefined,
    blocks: undefined,
    battery: undefined,
  });
  const [dataFetchError, setDataFetchError] = useState(false); //displays error message if simulation is incomplete or data on frontend is missing/loading message if the indexDb data has not yet been fetched
  const [loadProfileView, setLoadProfileView] = useState(true); //true= full_power/unmanaged, false= low_power/managed; used to keep both loadProfileGraph components viewing the opposite dataset when generating pdf
  /** @type {["ICE"|"GAS"|"PROP"|"CNG"|undefined]} */
  const [selectedFuel, setSelectedFuel] = useState("");
  const [isPerVehicle, setIsPerVehicle] = useState(false); // can be used to divide all fields by a number
  const [dropdownValue, setDropdownValue] = useState("summary");
  const [currentStep, setCurrentStep] = useState(0); // Default step to 0
  const [selectedCard, setSelectedCard] = useState(0);
  const [vehiclesBatteryData, setVehiclesBatteryData] = useState();
  const [designCase, setDesignCase] = useState({});
  const [isDesignCaseDialogOpen, setIsDesignCaseDialogOpen] = useState(false);
  const [isDesignCaseLoading, setIsDesignCaseLoading] = useState(false);

  const { snackBarElement } = useContext(SnackBarContext);
  const units = getUnits();

  useEffect(() => {
    async function fetchData() {
      try {
        if (!UseAuth("get")) {
          window.location.assign("/login"); ///TODO: should display error page
          return;
        }

        // Fetches all the simulation details from the IndexedDb
        localDb.transaction(
          "r",
          localDb.simulation,
          localDb.project,
          localDb.evAssessment,
          localDb.fleetSizing,
          localDb.tco,
          localDb.blocks,
          localDb.battery,
          async () => {
            const [
              simulationData,
              projectData,
              evAssessmentData,
              fleetSizingData,
              tcoData,
              blocksData,
              batteryData,
            ] = await Dexie.Promise.all([
              getLocalData("simulation"),
              getLocalData("project"),
              getLocalData("evAssessment"),
              getLocalData("fleetSizing"),
              getLocalData("tco"),
              getLocalData("blocks"),
              getLocalData("battery"),
            ]);

            const simData = {
              simulation: simulationData,
              project: projectData,
              evAssessment: evAssessmentData,
              fleetSizing: fleetSizingData,
              tco: tcoData,
              blocks: blocksData,
              battery: batteryData,
            };

            setSimData(simData);

            setSelectedFuel(
              Object.keys(tcoData?.data || {}).find(
                (i) => i in TYPE_STRINGS.FUEL_TYPE
              )
            );

            //checks that the frontend's indexDb contains all the required values
            if (
              !Object.values(simData).every(
                (i) => i?.data && Object.keys(i.data).length
              )
            )
              setDataFetchError(true);
          }
        );
      } catch (e) {
        console.log(e);
        snackBarElement.current.displayToast(
          "Something went wrong",
          "error",
          1500
        );
        setDataFetchError(true);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    function fetchData() {
      const headers = {
        Authorization: `Token ${UseAuth("get")}`,
        "Content-Type": "application/json",
      };

      // the below design case fetch is used to auto-fill design case dialogs with any pre-existing design case inputs
      getLocalData("simulation", "data").then(({ data: sim }) =>
        fetch(`${designCaseURL}?simulation_id=${sim.id}`, {
          method: "GET",
          headers,
        })
          .then((res) => {
            if (res.ok)
              res.json().then(({ data }) =>
                setDesignCase({
                  ...data[0],
                  id: sim.id,
                  name: sim.name,
                  depot_name: Cookie.get("depot_name"),
                })
              );
            else {
              setDesignCase({
                id: sim.id,
                name: sim.name,
                depot_name: Cookie.get("depot_name"),
              });
              errorHandler(
                res,
                snackBarElement,
                "Failed to fetch electification milestones"
              );
            }
          })
          .catch((e) => {
            console.log(e);
            setDesignCase({
              id: sim.id,
              name: sim.name,
              depot_name: Cookie.get("depot_name"),
            });
            snackBarElement?.current?.displayToast(
              "Network error: failed to fetch electrification milestones"
            );
          })
      );

      /**
       * The below is devoted entirely to performing the necessary backend fetch for vehicleBattery's view.
       * The purpose of performing this fetch here, instead of in the VehicleBattery component is to reduce load times, and redundant fetches.
       * Though, it could be expanded out to other page views, if necessary
       *
       * NOTE: unlike battery sizing, this view does not fetch all the master vehicles, since this page doesn't
       * need to handle the edit row scenario
       */
      getLocalData("battery", "data").then(({ data: batterySizing }) => {
        if (batterySizing?.blocks?.length)
          getMasterData(snackBarElement, {
            models: batterySizing.blocks.map((block) => block.vehicleModel),
            onSuccess: setVehiclesBatteryData,
          });
      });
    }
    fetchData();
  }, []);

  // Unique models
  const uniqueVehicleModelCount = new Set(
    simData.battery?.data?.blocks?.map((row) => row.vehicleModel)
  ).size;

  // fleet sizes (for use in tcoTable/tcoGraph/tcoTableEmissions)
  const fleetSizes =
    simData.evAssessment?.data?.fleet_size?.feasible || //analysis vers >1.2.0
    simData.evAssessment?.data?.fleet_size; //analysis vers <= 1.2.0

  // Type version for operations
  const typeVersion = simData.simulation?.data?.analysis_type_vers; // Assign analysisTypeVersion to typeVersion

  function sumFeasibleBlocks(simData) {
    if (
      simData &&
      simData.fleetSizing &&
      simData.fleetSizing.data &&
      simData.fleetSizing.input
    ) {
      const rowIndex = simData.fleetSizing.input.row;
      const selectedItem =
        simData.fleetSizing.data?.feasible?.combos?.[rowIndex] || //vers <= 1.2.0
        simData.fleetSizing.data?.feasible_blocks?.[rowIndex]; //vers > 1.2.0
      if (selectedItem && selectedItem.fleet) {
        const breakdownArray =
          selectedItem.fleet?.breakdown || selectedItem.fleet?.model_breakdown;
        const sumFeasibleBlocks = breakdownArray.reduce(
          (sum, item) => sum + (item.num_feasible_blocks || 0),
          0
        );

        return sumFeasibleBlocks;
      }
      return 0;
    }
  }

  const batteryPercentage = parseInt(
    (sumFeasibleBlocks(simData) /
      (simData.battery?.data?.blocks?.length ?? 1)) *
      100
  );

  const StyledCard = styled(Card)({
    height: "150px", // Adjust the height as needed
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f5f5f5", // Adjust the color as needed
    color: "black",
    borderColor: "black",
    margin: "0 10px 0 10px",
    cursor: "pointer",
    transition: "transform 0.2s", // Add a transition effect
    "&:hover": {
      transform: "scale(1.05)", // Slightly scale up on hover
    },
  });

  /**
   * extends grid component while making the card clickable for the different views
   */
  const ClickableCard = ({ onClick, children, ...props }) => (
    <Grid item xs={12} {...props}>
      <StyledCard onClick={onClick}>
        <CardContent
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {children}
        </CardContent>
      </StyledCard>
    </Grid>
  );

  // Define the lookup object
  const lookup = {
    1: "operations",
    2: "vehicles",
    3: "electricity",
    4: "chargers",
    5: "financial",
    6: "emissions",
    0: "summary",
  };

  const menuItems = Object.values(lookup);

  // Find index in menuItems
  const findMenuItemIndex = (reverseValue) =>
    menuItems.findIndex((item) => item === reverseValue);

  const handleDropdownChange = (event) => {
    const value = event.target.value;
    setDropdownValue(value);
    setSelectedCard(findMenuItemIndex(value));
    setCurrentStep(0); // Reset the current step to 0
  };

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setDropdownValue(menuItems[index]);
    setCurrentStep(0); // Reset the current step to 0
  };

  // ICE vs BEV graph for opex
  const ChartOpex = () => {
    /** @type {import("chart.js").ChartData} */
    const data = {
      labels: ["BEV", TYPE_STRINGS.FUEL_TYPE[selectedFuel]],
      datasets: [
        {
          data: [
            unitMoney(simData.tco.data.BEV.average_opex),
            unitMoney(simData.tco.data[selectedFuel].average_opex),
          ],
          backgroundColor: ["#1976d2", "#808080"], // Colors for BEV and ICE
          barThickness: 30,
        },
      ],
    };

    /** @type {import("chart.js").ChartOptions} */
    const options = {
      animations: false,
      indexAxis: "y",
      plugins: {
        legend: { display: false },
        tooltip: {
          callbacks: {
            label: (value) =>
              `${currencySymbol()}${Math.round(value.raw).toLocaleString()}`,
          },
        },
      },
      scales: {
        x: { grid: { display: false }, ticks: { display: false } },
        y: { grid: { display: false }, ticks: { display: true } },
      },
      maintainAspectRatio: false,
    };

    return (
      <div style={{ width: "150px", height: "100px" }}>
        <Bar data={data} options={options} />
      </div>
    );
  };

  const ChartCapex = () => {
    /** @type {import("chart.js").ChartData} */
    const data = {
      labels: ["BEV", TYPE_STRINGS.FUEL_TYPE[selectedFuel]],
      datasets: [
        {
          data: [
            unitMoney(simData.tco.data.BEV.initial_capital),
            unitMoney(simData.tco.data[selectedFuel].initial_capital),
          ],
          backgroundColor: ["#1976d2", "#808080"], // Colors for BEV and ICE
          barThickness: 30,
        },
      ],
    };

    /** @type {import("chart.js").ChartOptions} */
    const options = {
      animations: false,
      indexAxis: "y",
      plugins: {
        legend: { display: false },
        tooltip: {
          callbacks: {
            label: (value) =>
              `${currencySymbol()}${Math.round(value.raw).toLocaleString()}`,
          },
        },
      },
      scales: {
        x: { grid: { display: false }, ticks: { display: false } }, //hides x-axis gird lines, and hides y-axis labels
        y: { grid: { display: false }, ticks: { display: true } }, //hides y-axis grid lines, and shows y-axis labels
      },
      maintainAspectRatio: false,
    };

    return (
      <div style={{ width: "150px", height: "100px" }}>
        <Bar data={data} options={options} />
      </div>
    );
  };

  const ChartEmissions = () => {
    /** @type {import("chart.js").ChartData} */
    const data = {
      labels: ["BEV", TYPE_STRINGS.FUEL_TYPE[selectedFuel]],
      datasets: [
        {
          data: [
            unitPoundToTon(simData.tco.data.BEV.grid_emissions.CO2),
            unitPoundToTon(simData.tco.data[selectedFuel].grid_emissions.CO2),
          ],
          backgroundColor: ["#1976d2", "#808080"], // Colors for BEV and ICE
          barThickness: 30,
        },
      ],
    };

    /** @type {import("chart.js").ChartOptions} */
    const options = {
      animations: false,
      plugins: {
        legend: { display: false },
        tooltip: {
          callbacks: {
            label: (value) =>
              `${Math.round(value.raw).toLocaleString()} ${unitMassMap[units]}`,
          },
        },
      },
      scales: {
        x: { grid: { display: false }, ticks: { display: true } },
        y: { grid: { display: false }, ticks: { display: false } },
      },
      maintainAspectRatio: false,
    };

    return (
      <div style={{ width: "150px", height: "100px" }}>
        <Bar data={data} options={options} />
      </div>
    );
  };

  // Download button functionality
  const downloadPageContent = async () => {
    const containsImages = document.querySelectorAll("img, icon").length > 0;
    const containsTables = document.querySelectorAll("table").length > 0;

    if (containsImages) {
      // Download page as PNG
      const canvas = await html2canvas(document.body);
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = "page-content.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    if (containsTables) {
      // Extract tables and download as Excel
      const tables = document.querySelectorAll("table");
      const wb = XLSX.utils.book_new();

      tables.forEach((table, index) => {
        const ws = XLSX.utils.table_to_sheet(table);
        XLSX.utils.book_append_sheet(wb, ws, `Table${index + 1}`);
      });

      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([wbout], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "tables.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const Operations = () => {
    const steps = [
      "Timetable",
      "Distances",
      typeVersion !== "1.0.0" &&
      typeVersion !== "1.1.0" &&
      typeVersion !== "1.2.0"
        ? "Vehicle Schedule"
        : null,
    ].filter((step) => step !== null);

    const renderStepContent = (step) => {
      switch (step) {
        case 0:
          return <OperationView viewType="timetable" />;
        case 1:
          return <OperationView viewType="distances" />;
        case 2:
          return (
            <Container fixed maxWidth="xl">
              <br />
              <br />
              <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={3}>
                <VehicleActivityTimeTable
                  data={simData.evAssessment?.data?.operational_profile ?? []}
                  view={1}
                  setView={() => null}
                />
              </Paper>
            </Container>
          );
        default:
          return <div>Select a step to see the content.</div>;
      }
    };

    return (
      <div>
        <CustomStepper
          steps={steps}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
        />
        <div>{renderStepContent(currentStep)}</div>
      </div>
    );
  };

  const Vehicles = () => {
    const steps = ["Energy", "Battery"];

    const renderStepContent = (step) => {
      switch (step) {
        case 0:
          return <VehiclesEnergy />;
        case 1:
          return vehiclesBatteryData ? (
            <VehiclesBattery vehicleData={vehiclesBatteryData} />
          ) : (
            <>
              <CircularProgress />
              <br />
              Loading...
            </>
          );
        default:
          return <div>Select a step to see the content.</div>;
      }
    };

    return (
      <div>
        <CustomStepper
          steps={steps}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
        />
        <div>{renderStepContent(currentStep)}</div>
      </div>
    );
  };

  const Financials = () => {
    const steps = ["Chart", "Summary", "CAPEX", "OPEX"];
    const renderStepContent = (step) =>
      step == 0 ? (
        <Container maxWidth="xl">
          <div style={{ margin: "2rem" }}>
            <TCOGraph
              isPerVehicle={isPerVehicle}
              setIsPerVehicle={setIsPerVehicle}
              fleetSizes={fleetSizes}
              selectedFuel={selectedFuel}
              tcoData={simData.tco.data}
            />
          </div>
        </Container>
      ) : (
        <div style={{ margin: "2rem" }}>
          <TCOTable
            selectedFuel={selectedFuel}
            isPerVehicle={isPerVehicle}
            setIsPerVehicle={setIsPerVehicle}
            tcoData={simData.tco.data}
            fleetSizes={fleetSizes}
            className="print-break"
            tableView={step}
          />
        </div>
      );

    return (
      <div>
        <CustomStepper
          steps={steps}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
        />
        <div>{renderStepContent(currentStep)}</div>
      </div>
    );
  };

  const blockTime =
    simData.battery?.input?.settings?.bev_settings?.block_charging || [];

  // Generate annotations based on block_time array
  const annotations = useMemo(() => {
    return blockTime.reduce((acc, timeSlot, index) => {
      acc[`blockCharging${index}`] = {
        type: "box",
        xMin: timeSlot.start, // Start time in minutes
        xMax: timeSlot.end, // End time in minutes
        backgroundColor: "rgba(255, 99, 132, 0.25)",
        borderColor: "rgba(255, 99, 132, 0.6)",
        borderWidth: 1,
      };
      return acc;
    }, {});
  }, [blockTime]);

  /**
   * closes the design case dialog
   */
  function handleDesignCaseDialogClose() {
    setIsDesignCaseDialogOpen(false);
  }

  /**
   * Opens the design case dialog box, to CRUD the design case
   */
  function handleDesignCaseDialogOpen() {
    setIsDesignCaseDialogOpen(true);
  }

  /**
   * Creates, Updates, or Deletes the design case, depending on if it already existed or not
   * @param {import("react").FormEvent} event
   * @param {"POST"|"PATCH"}
   */
  async function handleDesignCaseCreateUpdate(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJSON = Object.fromEntries(formData.entries());

    const method = designCase.key_metrics ? "PATCH" : "POST";

    const method_lookup = { POST: "add", PATCH: "update" };

    setIsDesignCaseLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    if (!simData.simulation.data.locked) {
      //if simulation is not locked, then lock it first (really only used when POSTing a design case, as it's already locked if you're PATCHing one)
      //returns 0 for success, 1 for failure
      const locked = await fetch(toggleLockSimulationURL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ id: simData.simulation.data.id }),
      })
        .then((res) => {
          if (res.ok) {
            simData.simulation.data.locked = true;
            setSimData({ ...simData });
            storeLocalData("simulation", { data: simData.simulation.data });
            return 0;
          } else {
            errorHandler(res, snackBarElement, "Failed to lock simulation");
            return 1;
          }
        })
        .catch((e) => {
          console.log(e);
          snackBarElement?.current?.displayToast(
            "Network Error: Failed to lock simulation"
          );
          return 1;
        });
      if (locked == 1) {
        //if failed to lock simulation, exit function
        setIsDesignCaseLoading(false);
        return;
      }
    }

    let body = {
      analysis_id: designCase.id,
      is_primary: formJSON.is_primary == "on",
    };
    if (formJSON.absolute_year) body.absolute_year = formJSON.absolute_year;

    fetch(designCaseURL, {
      method: method,
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          Object.entries(formJSON).forEach(
            ([key, value]) => (designCase[key] = value)
          );
          designCase["key_metrics"] = {}; //to keep repeat visits consistent, since we don't actually need the key_metrics for this
          setDesignCase({ ...designCase });
          handleDesignCaseDialogClose();
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to ${method_lookup[method]} electrification milestones`
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "error",
          `Network Error: failed to ${method_lookup[method]} eletrification milestones`,
          5000
        );
      })
      .finally(() => {
        setIsDesignCaseLoading(false);
      });
  }

  /**
   * Deletes the design case of the currently open dialog box
   */
  function handleDesignCaseDelete(event) {
    event.preventDefault();

    setIsDesignCaseLoading(true);
    const headers = {
      Authorization: `Token ${UseAuth("get")}`,
      "Content-Type": "application/json",
    };

    const body = { analysis_id_list: [designCase.id] };

    fetch(designCaseURL, {
      method: "DELETE",
      headers,
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          setDesignCase({
            id: simData.simulation.data.id,
            name: simData.simulation.data.name,
            depot_name: Cookie.get("depot_name"),
            key_metrics: undefined,
          });
          handleDesignCaseDialogClose();
        } else
          errorHandler(
            response,
            snackBarElement,
            `Failed to remove electrification milestones`
          );
      })
      .catch((e) => {
        console.log(e);
        snackBarElement?.current?.displayToast(
          "error",
          `Network Error: failed to remove electrification milestones`,
          5000
        );
      })
      .finally(() => {
        setIsDesignCaseLoading(false);
      });
  }

  return (
    <div>
      {simData &&
      Object.values(simData).every(
        (i) => i?.data && Object.keys(i.data).length
      ) ? (
        <>
          <Container fixed maxWidth="xl" className="print-fullwidth">
            <Container
              maxWidth="xl"
              sx={{
                my: 3,
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                variant="h6"
                align="left"
                className="page-title"
                sx={{ marginRight: 2, whiteSpace: "nowrap" }}
              >
                Analysis&nbsp;Results&nbsp;Summary
              </Typography>
              <SimulationSubtitle />
              <Container
                maxWidth="xl"
                sx={{
                  my: 3,
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <DownloadIcon
                  onClick={downloadPageContent}
                  style={{ cursor: "pointer", marginRight: "8px" }}
                />
                <Select
                  value={dropdownValue}
                  onChange={handleDropdownChange}
                  sx={{ marginLeft: 0 }}
                >
                  {menuItems.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {stringCapitalize(item)}
                    </MenuItem>
                  ))}
                </Select>
                {typeVersion != "1.0.0" && (
                  <TextField
                    variant="outlined"
                    value={selectedFuel}
                    label="Fuel"
                    onChange={(e) => setSelectedFuel(e.target.value)}
                    select
                    sx={{ mx: 1 }}
                  >
                    {Object.keys(simData.tco.data)
                      .filter((i) => i in TYPE_STRINGS.FUEL_TYPE)
                      .map((value) => (
                        <MenuItem key={value} value={value}>
                          {TYPE_STRINGS.FUEL_TYPE[value]} Vehicles
                        </MenuItem>
                      ))}
                  </TextField>
                )}
              </Container>
            </Container>
          </Container>

          {selectedCard === 0 ? (
            <Container fixed maxWidth="xl">
              <Grid container spacing={1}>
                {/* Operations chip */}
                <ClickableCard sm={6} onClick={() => handleCardClick(1)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Operations
                    <br />
                    <AssignmentIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <BatteryIcon percentage={batteryPercentage} />
                  <Typography variant="h3" noWrap sx={{ fontSize: 30 }}>
                    <Typography
                      variant="caption"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>Electrified</span>/<span>Analyzed</span>/
                      <span>Total</span>
                    </Typography>
                    {sumFeasibleBlocks(simData)} /{" "}
                    {simData.battery?.data?.blocks?.length} /{" "}
                    {simData.blocks?.data?.length}
                    <br />
                    <Typography variant="body2">
                      Operations Electrified
                    </Typography>
                  </Typography>
                </ClickableCard>
                {/* Vehicles Chip */}
                <ClickableCard sm={6} onClick={() => handleCardClick(2)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Vehicles
                    <br />
                    <ElectricCarIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {simData.evAssessment.data.fleet_size.total_ev_fleet_size || //analysis vers < 1.2.0
                      simData.evAssessment.data.fleet_size.feasible //analysis vers > 1.2.0
                        .total_ev_fleet_size ||
                      "NA"}
                    <br />
                    <Typography variant="body2">Electric Vehicles</Typography>
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {uniqueVehicleModelCount || "NA"}
                    <br />
                    <Typography variant="body2">Unique Models</Typography>
                  </Typography>
                </ClickableCard>
                {/* Electricity Chip */}
                <ClickableCard sm={6} onClick={() => handleCardClick(3)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Electricity
                    <br />
                    <BoltIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 30 }}>
                    {parseInt(
                      simData.evAssessment.data.maximum_power_demand
                        .total_max_demand || "NA"
                    )}
                    <br />
                    <Typography variant="body2">Peak Load (kW)</Typography>
                    <br />
                    {simData.tco.data.BEV.transformer_rating_unmanaged || "NA"}
                    <br />
                    <Typography variant="body2">
                      Min. Recommended Transformer (kVA)
                    </Typography>
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 30 }}>
                    {parseInt(simData.tco.data.BEV.fuel_usage / 1000) || "NA"}
                    <br />
                    <Typography variant="body2">Annual Energy (MWh)</Typography>
                  </Typography>
                </ClickableCard>
                {/* Chargers Chip */}
                <ClickableCard sm={6} onClick={() => handleCardClick(4)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Chargers
                    <br />
                    <EvStationIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {simData.evAssessment.data.num_chargers || "NA"}
                    <br />
                    <Typography variant="body2">Charging ports</Typography>
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {simData.fleetSizing.data?.feasible?.combos?.[ //analysis vers <= 1.2.0
                      simData.fleetSizing?.input?.row
                    ]?.charger_model?.rating ??
                      simData.fleetSizing.data?.feasible_blocks?.[ //analysis vers > 1.2.0
                        simData.fleetSizing?.input?.row
                      ]?.charger_model?.rating ??
                      "NA"}
                    <br />
                    <Typography variant="body2">Average Rating (kW)</Typography>
                  </Typography>
                </ClickableCard>

                {/* Financials Chip */}
                <ClickableCard onClick={() => handleCardClick(5)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Financials
                    <br />
                    <AttachMoneyIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <div>
                    <ChartCapex />
                  </div>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {currencySymbol()}
                    {numberUS.format(
                      Math.round(
                        unitMoney(simData.tco.data.BEV.initial_capital)
                      )
                    )}
                    <br />
                    {currencySymbol()}
                    {numberUS.format(
                      Math.round(
                        unitMoney(
                          simData.tco.data[selectedFuel].initial_capital
                        )
                      )
                    )}
                    <br />
                    <Typography variant="body2">Capital Expenditure</Typography>
                  </Typography>
                  <div>
                    <ChartOpex />
                  </div>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {currencySymbol()}
                    {numberUS.format(
                      Math.round(unitMoney(simData.tco.data.BEV.average_opex))
                    )}
                    <br />
                    {currencySymbol()}
                    {numberUS.format(
                      Math.round(
                        unitMoney(simData.tco.data[selectedFuel].average_opex)
                      )
                    )}
                    <br />
                    <Typography variant="body2">
                      Annual Operating Expenditure
                    </Typography>
                  </Typography>
                  {/** span is to pad out the right side of the card (via parent's justifyContent: space-between) */}
                  <span />
                </ClickableCard>
                {/* Emissions Chip */}
                <ClickableCard onClick={() => handleCardClick(6)}>
                  <Typography variant="h5" style={{ fontWeight: "bold" }}>
                    Emissions
                    <br />
                    <FactoryIcon style={{ fontSize: "80px" }} />
                  </Typography>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {numberUS.format(
                      Math.round(
                        unitTon(
                          unitPoundToTon(
                            simData.tco.data[selectedFuel].grid_emissions.CO2 -
                              simData.tco.data.BEV.grid_emissions.CO2
                          )
                        )
                      )
                    )}{" "}
                    <br />
                    <Typography variant="body2">
                      {stringCapitalize(unitMassMap[units])} CO2e Saved Annually
                    </Typography>
                  </Typography>
                  <div>
                    <ChartEmissions />
                  </div>
                  <Typography variant="h3" noWrap sx={{ fontSize: 40 }}>
                    {numberUS.format(
                      Math.round(
                        unitAcres(
                          (unitPoundToTon(
                            simData.tco.data[selectedFuel].grid_emissions.CO2 -
                              simData.tco.data.BEV.grid_emissions.CO2
                          ) *
                            0.907) /
                            0.3
                        )
                      )
                    )}
                    <br />
                    <Typography variant="body2">
                      Equivalent {unitAreaMap[units]}s Forest Planted
                    </Typography>
                  </Typography>
                  {/** span is to pad out the right side of the card (via parent's justifyContent: space-between) */}
                  <span />
                </ClickableCard>
              </Grid>
            </Container>
          ) : (
            <>
              {selectedCard === 1 && (
                <Container fixed maxWidth="xl">
                  <Operations />
                </Container>
              )}
              {selectedCard === 2 && (
                <Container fixed maxWidth="xl">
                  <Vehicles />
                </Container>
              )}
              {selectedCard === 3 && (
                <Container fixed maxWidth="xl">
                  <LoadProfileGraph
                    type={loadProfileView}
                    setType={setLoadProfileView}
                    loadProfile={simData.evAssessment.data.load_profile}
                    tcoData={simData.tco.data} //for the transformer capacity
                    className="print-break"
                    annotations={annotations} // Pass annotations here
                  />
                  <LoadProfileGraph
                    type={!loadProfileView}
                    loadProfile={simData.evAssessment.data.load_profile}
                    tcoData={simData.tco.data} //for the transformer capacity
                    className="print-break"
                    printOnly={true}
                    annotations={annotations} // Pass annotations here
                  />
                </Container>
              )}
              {selectedCard === 4 && (
                <Container fixed maxWidth="xl">
                  <Chargers />
                </Container>
              )}
              {selectedCard === 5 && <Financials />}
              {selectedCard === 6 && (
                <>
                  <Container fixed maxWidth="xl">
                    <Paper sx={{ ml: 2, mr: 2 }}>
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            align="left"
                            sx={{
                              position: "absolute",
                              fontWeight: 500,
                              fontSize: 25,
                              marginLeft: 2,
                            }}
                          >
                            Net Emissions Savings
                          </Typography>
                          {/* add a bit of whitespace for the header, for smaller screens */}
                          <br />
                          <Grid container alignItems="center" sx={{ my: 3 }}>
                            <Grid item xs={5}>
                              <img
                                src={CO2}
                                alt="CO2"
                                style={{ maxWidth: "60%" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Typography
                                variant="h3"
                                noWrap
                                align="left"
                                sx={{ pt: 2 }}
                              >
                                {numberUS.format(
                                  Math.round(
                                    unitTon(
                                      unitPoundToTon(
                                        simData.tco.data[selectedFuel]
                                          .grid_emissions.CO2 -
                                          simData.tco.data.BEV.grid_emissions
                                            .CO2
                                      )
                                    )
                                  )
                                )}{" "}
                              </Typography>
                              <Typography variant="h5" noWrap align="left">
                                {stringCapitalize(unitMassMap[units])} per Year
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Divider orientation="horizontal" flexItem />
                        <Grid item xs={12} sm={6}>
                          <Typography
                            align="left"
                            sx={{
                              fontWeight: 500,
                              fontSize: 25,
                              marginTop: "0.5em",
                            }}
                          >
                            Equivalent to CO<sub>2</sub>e Sequestered by
                            <br />
                          </Typography>
                          <Grid container alignItems="center">
                            <Grid item xs={5}>
                              <img
                                src={Trees}
                                alt="Trees"
                                style={{ maxWidth: "60%" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              alignItems={"center"}
                              justifyContent={"center"}
                            >
                              <Typography variant="h3" align="left">
                                {numberUS.format(
                                  Math.round(
                                    unitAcres(
                                      (unitPoundToTon(
                                        simData.tco.data[selectedFuel]
                                          .grid_emissions.CO2 -
                                          simData.tco.data.BEV.grid_emissions
                                            .CO2
                                      ) *
                                        0.907) /
                                        0.3
                                    )
                                  )
                                )}
                              </Typography>
                              <Typography variant="h5" noWrap align="left">
                                {unitAreaMap[units]}s of Forest
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Container>

                  <div style={{ margin: "1rem" }}>
                    <TCOTableEmissions
                      selectedFuel={selectedFuel}
                      isPerVehicle={isPerVehicle}
                      setIsPerVehicle={setIsPerVehicle}
                      tcoData={simData.tco.data}
                      fleetSizes={fleetSizes}
                      className="print-break"
                    />
                  </div>
                </>
              )}
            </>
          )}
          <br />
          <br />
          <Container>
            <Stack px={4}>
              <Tooltip
                title={`${
                  designCase.key_metrics ? "Update" : "Set as"
                } Electrification Milestone`}
              >
                <LoadingButton
                  onClick={handleDesignCaseDialogOpen}
                  variant="contained"
                  className="btn"
                  loading={!designCase.id} // display loading until design case fetch finishes
                  disabled={!simData.simulation?.data?.id} //simulation data should be available to make a design case for it
                >
                  {designCase.key_metrics ? "Update" : "Set as"} Electrification
                  Milestone
                </LoadingButton>
              </Tooltip>
            </Stack>
          </Container>
        </>
      ) : dataFetchError ? (
        //if the project_lookup fetch succeeded, but another fetch failed, display this message
        <Empty text={<>Completed Analysis Summary Not Found</>} />
      ) : (
        // displayed when fetching projects for project_lookup table
        <Fade in={true}>
          <div className="centered">
            <CircularProgress />
          </div>
        </Fade>
      )}
      <DesignCaseDialog
        open={isDesignCaseDialogOpen}
        loading={isDesignCaseLoading}
        onClose={handleDesignCaseDialogClose}
        onSubmit={handleDesignCaseCreateUpdate}
        onDelete={handleDesignCaseDelete}
        designCase={designCase}
      />
    </div>
  );
}
